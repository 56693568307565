import "../wdyr";
import "@archetype/ui/globals.css";
import { builderTrpc as trpc } from "@archetype/trpc-react";
import { generateTitle } from "@archetype/utils";
import { BaselimeRum } from "@baselime/react-rum";
import { ClerkProvider } from "@clerk/nextjs";
import { datadogRum } from "@datadog/browser-rum";
import { Analytics } from "@vercel/analytics/react";
import type { NextPage } from "next";
import { Noto_Emoji } from "next/font/google";
import localFont from "next/font/local";
import Head from "next/head";
import { useEffect, useState } from "react";
import { UserMonitoringProvider } from "../components/monitoring/UserMonitoringProvider";
import { PostHogProvider } from "../providers";
const notoEmoji = Noto_Emoji({
  weight: "400",
  subsets: ["emoji"]
});

// TODO @ShaunSpringer make this reusable -- its currently in 4 places (storybooks and apps)
const diatypeFont = localFont({
  src: [{
    path: "../../public/fonts/diatype/ABCDiatype-Light.woff",
    weight: "300",
    style: "normal"
  }, {
    path: "../../public/fonts/diatype/ABCDiatype-Regular.woff",
    weight: "400",
    style: "normal"
  }, {
    path: "../../public/fonts/diatype/ABCDiatype-Medium.woff",
    weight: "500",
    style: "normal"
  }, {
    path: "../../public/fonts/diatype/ABCDiatype-Bold.woff",
    weight: "600",
    style: "normal"
  }],
  variable: "--font-diatype"
});
const diatypeFontMono = localFont({
  src: [{
    path: "../../public/fonts/diatype-mono/ABCDiatypeMono-Regular.woff",
    weight: "400",
    style: "normal"
  }, {
    path: "../../public/fonts/diatype-mono/ABCDiatypeMono-Medium.woff",
    weight: "500",
    style: "normal"
  }, {
    path: "../../public/fonts/diatype-mono/ABCDiatypeMono-Bold.woff",
    weight: "700",
    style: "normal"
  }],
  variable: "--font-diatype-mono"
});
datadogRum.init({
  applicationId: "d921c200-5294-4539-8d5b-182868a9a841",
  //todo: move to const
  clientToken: "pub158ef6e9de3255dc8032da31ecb40d98",
  //todo: move to env var
  site: "datadoghq.eu",
  service: "builder",
  env: process.env.NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input"
});
function App<T extends object>({
  pageProps,
  Component
}: {
  pageProps: T;
  Component: NextPage<T>;
}): React.ReactNode {
  const [isServer, setIsServer] = useState(true);
  useEffect(() => {
    setIsServer(false);
  }, []);
  if (isServer) {
    return null;
  }
  return <div suppressHydrationWarning className="flex h-max min-h-dvh" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
        <title>{generateTitle()}</title>
        <meta content="minimum-scale=1, initial-scale=1, width=device-width" name="viewport" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
      </Head>
      <style global jsx>
        {`
          :root {
            --font-diatype: ${diatypeFont.style.fontFamily};
            --font-diatype-mono: ${diatypeFontMono.style.fontFamily};
            --font-noto-emoji: ${notoEmoji.style.fontFamily};
          }

          #__next {
            min-height: 100dvh;
          }
        `}
      </style>
      <PostHogProvider data-sentry-element="PostHogProvider" data-sentry-source-file="_app.tsx">
        <BaselimeRum enableWebVitals apiKey={process.env.NEXT_PUBLIC_BASELIME_API_KEY ?? ""} data-sentry-element="BaselimeRum" data-sentry-source-file="_app.tsx">
          <ClerkProvider {...pageProps} data-sentry-element="ClerkProvider" data-sentry-source-file="_app.tsx">
            <UserMonitoringProvider data-sentry-element="UserMonitoringProvider" data-sentry-source-file="_app.tsx">
              {typeof window === "undefined" ? null : <Component {...pageProps} />}
            </UserMonitoringProvider>
          </ClerkProvider>
        </BaselimeRum>
        <Analytics data-sentry-element="Analytics" data-sentry-source-file="_app.tsx" />
      </PostHogProvider>
    </div>;
}
export default trpc.withTRPC(App);