import { forEach, has } from "lodash";

export function isObjectSubset(objectA: object, objectB: object): boolean {
  let foundInANotB = false;

  forEach(objectA, (_value, key) => {
    if (!foundInANotB === has(objectA, key) && !has(objectB, key)) {
      foundInANotB = true;
    }
  });

  return !foundInANotB;
}
