import { forEach, has } from "lodash";

export const richKeysDifference = (args: {
  objectA: object;
  objectB: object;
}): {
  inANotB: string[];
  inBNotA: string[];
} => {
  const { objectA, objectB } = args;

  const inANotB: string[] = [];
  const inBNotA: string[] = [];

  forEach(objectA, (_value, key) => {
    if (has(objectA, key) && !has(objectB, key)) {
      inANotB.push(key);
    }
  });

  forEach(objectB, (_value, key) => {
    if (has(objectB, key) && !has(objectA, key)) {
      inBNotA.push(key);
    }
  });

  return {
    inANotB,
    inBNotA,
  };
};
