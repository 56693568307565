import { camelCase, capitalize, snakeCase, startCase, trim } from "lodash";

import type { IReadableString } from "./types/ReadableString";

const isUppercase = (str: string): boolean => str === str.toUpperCase();

export function humanize(str: string): IReadableString {
  if (isUppercase(str)) {
    return startCase(camelCase(trim(str))) as IReadableString;
  }

  // If the words are not fully uppercase, this will leave an uppercase word untouched e.g. "IT Approval"
  return startCase(trim(str)) as IReadableString;
}

export function humanizeIdLike(str: string): IReadableString {
  return capitalize(trim(snakeCase(str).replace(/-id$/, "").replace(/_id$/, "").replace(/_/g, " "))) as IReadableString;
}

export function convertToTitleCase(str: string | null): string {
  if (str == null || !str) {
    return "";
  }
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
}
