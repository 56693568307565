import { entries, forEach, keys, map, mapValues, omitBy, pickBy, toPairs } from "lodash";

import { isNonNullable } from "./isNonNullable";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IToPairsObject<T extends Record<string, any> | undefined> = T extends undefined
  ? []
  : {
      [Key in keyof T]: [Key, T[Key]];
    }[keyof T][];

/**
 * Converts an object into an array of key-value pairs.
 *
 * Unlike the original `toPairs` function, this function retains the key type.
 *
 * @param obj The object to convert.
 * @returns An array of key-value pairs.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toPairsStronglyTyped<const T extends Record<string, any> | undefined>(obj: T): IToPairsObject<T> {
  return toPairs(obj) as IToPairsObject<T>;
}

export function keysStronglyTyped<const T extends object>(obj: T): (keyof T)[] {
  return keys(obj) as (keyof T)[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapStronglyTyped<const T extends Record<string, any>, R>(
  obj: T,
  iteratee: (value: T[keyof T], key: keyof T, obj: T) => R,
): R[] {
  return map(obj, iteratee);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function forEachStronglyTyped<const T extends Record<string, any>, R>(
  obj: T,
  iteratee: (value: T[keyof T], key: keyof T, obj: T) => R,
): void {
  forEach(obj, iteratee);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapValuesStronglyTyped<const T extends Record<string, any>, R>(
  obj: T,
  iteratee: (value: T[keyof T], key: keyof T, obj: T) => R,
): Record<keyof T, R> {
  return mapValues(obj, iteratee) as Record<keyof T, R>;
}

export function omitByStronglyTyped<
  const Obj extends { [key: string | number | symbol]: unknown },
  Predicate extends (x: Obj[keyof Obj], key: keyof Obj) => boolean,
>(obj: Obj, predicate: Predicate): Obj;
export function omitByStronglyTyped<
  const Obj extends { [key: string | number | symbol]: Wide },
  Wide,
  Narrow extends Wide,
  Predicate extends (x: Wide, key: keyof Obj) => x is Narrow,
>(obj: Obj, predicate: Predicate): Omit<Obj, { [Key in keyof Obj]: Obj[Key] extends Narrow ? Key : never }[keyof Obj]> {
  return omitBy(obj, predicate) as Omit<Obj, { [Key in keyof Obj]: Obj[Key] extends Narrow ? Key : never }[keyof Obj]>;
}

export function unpartialRecord<const T extends { [key: string | number | symbol]: unknown }>(
  rec: T,
): Record<keyof T, Exclude<T[keyof T], undefined>> {
  return pickBy(rec, isNonNullable) as { [key in keyof T]-?: T[key] extends undefined ? never : T[key] };
}

export function entriesStronglyTyped<const T extends object>(obj: T): [keyof T, T[keyof T]][] {
  return entries(obj) as [keyof T, T[keyof T]][];
}
