import { forEach } from "lodash";

/**
 *
 * Maps the keys of the object with the function provided, discarding any values where the function returns `undefined`
 */
export function mapKeysNoUndefined<ORIG_K extends string, E, TARG_K extends string>(
  object: Record<ORIG_K, E>,
  mapKey: (value: E, key: ORIG_K) => TARG_K | undefined,
): Record<TARG_K, E> {
  const res = {} as Record<TARG_K, E>;

  forEach(object, (value, key) => {
    const newKey = mapKey(value, key as ORIG_K);

    if (newKey == null) {
      return;
    }
    res[newKey] = value;
  });

  return res;
}
