export const removeLoopsInArray = <T extends string>(path: T[]): T[] => {
  const seen = new Set();
  let firstDuplicateIndex = -1;

  // Find the index of the first duplicate
  for (let i = 0; i < path.length; i++) {
    if (seen.has(path[i])) {
      firstDuplicateIndex = i;
      break;
    }
    seen.add(path[i]);
  }

  if (firstDuplicateIndex === -1) {
    // No duplicates found, return the original path
    return path;
  }

  // Find the index of the first occurrence of the duplicate
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const duplicateValue = path[firstDuplicateIndex]!;
  const firstOccurrenceIndex = path.indexOf(duplicateValue);

  // Remove elements between the first occurrence and the first duplicate, includes the duplicated only once
  const newPathWithoutOneLoop = [...path.slice(0, firstOccurrenceIndex), ...path.slice(firstDuplicateIndex)];

  // Recurse to remove a potential other loop
  return removeLoopsInArray(newPathWithoutOneLoop);
};
