import { useUser } from "@clerk/nextjs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import type { PropsWithChildren } from "react";
import { useEffect } from "react";

/**
 * Adds global context to relevant monitoring services
 */
export const UserMonitoringProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const {
    user
  } = useUser();
  const router = useRouter();
  useEffect(() => {
    if (user == null) {
      return;
    }
    const allEmailAddresses = user.emailAddresses.map(email => email.emailAddress).join(", ");
    Sentry.setUser({
      ...Sentry.getCurrentScope()?.getUser(),
      email: user.emailAddresses?.[0]?.emailAddress,
      allEmailAddresses,
      id: user.id,
      username: user.username ?? undefined,
      name: user.fullName ?? undefined,
      firstName: user.firstName ?? undefined,
      lastName: user.lastName ?? undefined
    });
    datadogRum.setUser({
      ...datadogRum.getUser(),
      id: user.id,
      email: user.emailAddresses?.[0]?.emailAddress,
      allEmailAddresses,
      name: user.fullName ?? undefined,
      username: user.username ?? undefined
    });
  }, [user]);
  useEffect(() => {
    const orgId = router.query.orgId;
    Sentry.setTags({
      organizationId: orgId?.toString() ?? undefined
    });
    datadogRum.setGlobalContext({
      organizationId: orgId?.toString() ?? undefined
    });
  }, [router.query]);
  return children;
};