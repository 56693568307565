import { forEach } from "lodash";

/**
 *
 * Creates an object from an array with keys from the function provided, discarding any values where the function returns `undefined`
 */
export function keyByNoUndefined<const K extends string, E, T extends Array<E>>(
  object: T,
  getKey: (value: T[number], index: number) => K | undefined,
): Record<K, T[number]> {
  const res: Record<K, T[number]> = {} as Record<K, T[number]>;

  forEach(object, (value, key) => {
    const newKey = getKey(value, key);

    if (newKey == null) {
      return;
    }
    res[newKey] = value;
  });

  return res;
}
