import { forEach } from "lodash";

/**
 *
 * Groups the elements of an array by the keys from the function provided, discarding any values where the function returns `undefined`
 */
export function groupByNoUndefined<E, T extends Array<E>, K extends string = string>(
  object: T,
  getKey: (value: T[number], index: number) => K | undefined,
): Record<K, T[number][]> {
  const res: Record<K, E[]> = {} as Record<K, E[]>;

  forEach(object, (value, key) => {
    const newKey = getKey(value, key);

    if (newKey == null) {
      return;
    }
    if (res[newKey] == null) {
      res[newKey] = [] as E[];
    }

    res[newKey]?.push(value);
  });

  return res;
}
