export const generateTitle = (pageTitle?: string, isBuilder: boolean = false): string => {
  let title = "";

  if (isBuilder) {
    title += "✏️ ";
  }

  title += pageTitle != null ? `${pageTitle} | Archetype Labs` : "Archetype Labs";
  return title;
};
